import tw, { styled } from "twin.macro";

export const Wrapper = styled.div`
  ${tw`flex flex-row w-20 justify-between items-center text-[0.75rem] font-semibold
     px-3 rounded-[8.5rem] shadow-sm`}
  ${({ variant }) =>
    variant === "small" && tw`py-1 bg-lightGray dark:bg-mediumGray`}
  ${({ variant }) =>
    variant === "big" && tw`py-2 bg-primary dark:text-primary dark:bg-primary`}
  span {
    ${tw`text-textGray text-[0.5rem]`}
  }
`;
