import React from "react";
import PropTypes from "prop-types";
import { Image, Name, Wrapper } from "./ParentCategoryCard.styles";

function ParentCategoryCard({ name, image, ...rest }) {
  return (
    <Wrapper {...rest}>
      <Image src={image} />
      <Name>{name}</Name>
    </Wrapper>
  );
}

ParentCategoryCard.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};

export default ParentCategoryCard;
