import create from "zustand";
import uniq from 'lodash-es/uniq'
import { persist } from "zustand/middleware";
import { USER_ORDERS } from "config/localStorage";

const useOrders = create(
  persist(
    (set, get) => ({
      orders: [],
      addOrder: (id) => {
        set((state) => ({
          orders: uniq([...state.orders, id]),
        }));
      },
    }),
    {
      name: USER_ORDERS,
      getStorage: () => localStorage,
    }
  )
);

export default useOrders;
