import tw from "twin.macro";
import styled from "styled-components";

export const TabContainer = tw.li`
  inline-flex flex-col justify-center gap-1 items-center cursor-pointer 
 focus:border-b-2 focus:border-b-black 
`;

export const TabImage = tw.img`
 w-10 h-10 p-0.5 rounded-full 
`;
export const TabTitle = styled.span`
  ${tw`text-xs  dark:text-primary whitespace-nowrap text-center lowercase`}
  :first-letter {
    ${tw`uppercase`}
  }
`;