import { useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { ChevronLeftIcon, ShoppingBagIcon } from "@heroicons/react/24/solid";
import Amount from "components/Amount";
import IconButton from "components/IconButton";
import ImageCard from "components/ImageCard";
import Layout from "components/Layout";
import Button from "components/Button";
import Title from "components/Title";
import SkeletonLoader from "components/SkeletonLoader";
import { Description, ProductInfos, Wrapper } from "./Details.styles";
import { GET_PRODUCTS, GET_SCHEDULES } from "config/queries";
import { getProductById } from "services";
import { getSchedules } from "services/schedule";
import useCart from "hooks/useCart";
import mediaHelper from "lib/mediaHelper";
import ts from "lib/translateString";
import dateToDayMonthYear from "lib/dateToDayMonthYear";
import Calendar from "./Calendar";

function Detail() {
  const [value, setValue] = useState(1);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedSchedule, setSelectedSchedule] = useState();
  const { t } = useTranslation();
  const navigate = useNavigate();
  let { productId } = useParams();
  const addTocart = useCart((state) => state.addTocart);

  const dateToObject = useMemo(
    () => dateToDayMonthYear(selectedDate),
    [selectedDate]
  );

  const { data: productDetails } = useQuery(GET_PRODUCTS + productId, () =>
    getProductById({
      productId,
    })
  );

  const scheduleList = useQuery(
    GET_SCHEDULES +
      productDetails?.department?._id +
      JSON.stringify(dateToObject),
    () =>
      getSchedules({
        departmentId: productDetails?.department?._id,
        year: dateToObject?.year,
        month: dateToObject?.month,
        day: dateToObject?.day,
      })
  );

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Layout>
      {productDetails?._id ? (
        <Wrapper>
          <ImageCard
            id={productDetails._id}
            alt={ts("name", productDetails)}
            imageSrc={mediaHelper(productDetails.media)}
            disableFav
          >
            <IconButton
              rounded={false}
              Icon={ChevronLeftIcon}
              onClick={goBack}
            />
          </ImageCard>
          <ProductInfos>
            <Title title={ts("name", productDetails)} fontSize="md" />
            <Amount
              value={value}
              setValue={setValue}
              price={productDetails.price}
              currency={productDetails.currency}
            />
            <Description>{ts("description", productDetails)}</Description>
          </ProductInfos>

          {productDetails?.duration > 0 && (
            <Calendar
              setSelectedSchedule={setSelectedSchedule}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              openingHours={productDetails?.department?.openingHours}
              bookedSpots={scheduleList?.data}
              duration={productDetails?.duration}
              departmentId={productDetails?.department?._id}
            />
          )}

          {productDetails?.takeOrders && (
            <Button
              disabled={productDetails?.duration && !selectedSchedule}
              size="small"
              label={t("addToCart")}
              color="secondary"
              textColor="primary"
              Icon={ShoppingBagIcon}
              onClick={() => {
                Array.from(Array(value).keys()).forEach((k) =>
                  addTocart(productDetails?._id, selectedSchedule, productDetails?.department?._id)
                );
                navigate("/order");
              }}
            />
          )}
        </Wrapper>
      ): <SkeletonLoader />}
    </Layout>
  );
}

export default Detail;
