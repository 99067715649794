import PropTypes from "prop-types";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
//components
import Tab from "./Tab";
import filterProductsByCategory from "lib/filterProductByCat";

function Carousel({ categories, products }) {
  return (
    <Swiper className="w-full h-full" spaceBetween={20} slidesPerView="auto">
      {categories &&
        categories.map(
          (category) =>
            filterProductsByCategory(products, category.id) && (
              <SwiperSlide className="tabs" key={category.id}>
                <Tab {...category} />
              </SwiperSlide>
            )
        )}
    </Swiper>
  );
}

Carousel.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape(Tab.propTypes)),
};

export default Carousel;
