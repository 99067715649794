import tw, { styled } from "twin.macro";

export const Wrapper = tw.div`
 flex flex-col items-start min-w-[85%] mx-auto mb-4 bg-lightGray text-black rounded-[1.15rem]
 dark:bg-darkGray dark:text-primary
`;

export const Description = styled.div`
  ${tw`flex flex-col justify-center my-4 pl-4 
    font-semibold text-base dark:text-primary`}
  span {
    display: inline-block;
  }
`;

export const DeliveryInfo = tw.div`
    flex flex-row text-textGray text-[0.75rem] gap-2
`;

export const Services = tw.ul`
    flex flex-row justify-center mt-2 text-[0.9rem] font-normal dark:text-textGray
`;

export const Service = tw.li`
    text-[0.75rem] text-primary dark:text-textGray mr-2 px-2 bg-secondary dark:bg-mediumGray rounded-lg
`;
