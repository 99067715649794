import tw, { styled } from "twin.macro";

export const Wrapper = styled.div`
  ${tw`w-[95%] mx-auto flex flex-col items-center bg-primary dark:bg-primarybg text-center`};
  span {
    ${tw`text-secondary mb-3.5`}
  }
`;
export const Stars = tw.div`
    flex flex-row gap-2
`;

export const StyledIcon = tw.button`
 w-8 text-primary dark:text-darkGray
`;
