import { useState } from "react";
import PropTypes from "prop-types";
import useCart from "hooks/useCart";
//components
import Button from "../Button";
import Modal from "components/Modal";
import { StyledTextArea } from "components/Input/Input.styles";
import Title from "components/Title";
import { Spacing } from "components/Layout/Spacing";

//styles
import {
  ArrivalInfo,
  Buttons,
  Image,
  Info,
  ItemsInfo,
  ItemsInfoContainer,
  TextArrival,
  TimeArrival,
  Wrapper,
} from "./OrderCard.styles";

function OrderCard({
  estimatedArrival,
  deliveryState,

  title,
  imageSrc,
  cartId,
}) {
  const removeFromCart = useCart((state) => state.removeFromCart);
  const addNote = useCart((state) => state.addNote);
  const [showNoteInput, setShowNoteInput] = useState(false);
  const [note, setNote] = useState(null);

  return (
    <Wrapper>
      <ItemsInfoContainer>
        <ItemsInfo>
          <Image src={imageSrc} />
          <Info>
            <span>{title}</span>
            {title && <p>{title}</p>}
          </Info>
        </ItemsInfo>
      </ItemsInfoContainer>
      <ArrivalInfo>
        {estimatedArrival && (
          <TimeArrival>
            <span className="leading-[0px]">Estimated Arrival</span>
            <p>
              {estimatedArrival} <span>min</span>
            </p>
          </TimeArrival>
        )}
        {deliveryState && (
          <TextArrival>
            <span>Now</span>
            <p>{deliveryState}</p>
          </TextArrival>
        )}
      </ArrivalInfo>
      <Buttons>
        <Button
          size="small"
          label="Remove"
          onClick={() => removeFromCart(cartId)}
        />
        <Button
          size="small"
          label="Add Note"
          onClick={() => setShowNoteInput(true)}
        />
      </Buttons>

      <Modal isOpen={showNoteInput} closeModal={() => setShowNoteInput(false)}>
        {title && <Title fontSize="mdLight">📝 {title}</Title>}
        <Spacing bottom={12} />

        <StyledTextArea onChange={(e) => setNote(e.target.value)} />
        <Spacing bottom={45} />
        <Button
          label="Add Note"
          onClick={() => {
            addNote(cartId, note);
            setNote(null);
            setShowNoteInput(false);
          }}
        />
      </Modal>
    </Wrapper>
  );
}

OrderCard.propTypes = {
  estimatedArrival: PropTypes.number,
  orderId: PropTypes.string,
  imageSrc: PropTypes.string,
  title: PropTypes.string,
  deliveryState: PropTypes.string,
};

export default OrderCard;
