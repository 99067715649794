import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useParams, useNavigate } from "react-router-dom";
import { Reveal } from "react-reveal";
import Input from "components/Input";
import Carousel from "components/Carousel";
import TabsCarousel from "components/TabsCarousel";
import Section from "components/Section";
import Layout from "components/Layout";
import ProductCard from "components/ProductCard";
import filterProductsByCategory from "lib/filterProductByCat";
import SkeletonAnimatedLoader from "components/SkeletonAnimatedLoader";
import SkeletonLoader from "components/SkeletonLoader";
import { Warpper } from "./Categories.styles";
import { GET_PRODUCTS, GET_CATEGORIES } from "config/queries";
import { getProducts, getCategories } from "services";
import { adaptCategories, adaptProducts } from "lib/dataAdapter";
import usePlace from "hooks/usePlace";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import Title from "components/Title";

export default function Categories() {
  const { t } = useTranslation();
  let navigate = useNavigate();
  let { categoryId } = useParams();
  const selected = usePlace((state) => state.selected);
  const [serchTerm, setSerchTerm] = useState();
  const [categories, setCategories] = useState(null);
  const [products, setProducts] = useState(null);
  console.log(categories);
  console.log(products);

  const { data: productList } = useQuery(GET_PRODUCTS, () =>
    getProducts({
      selected,
    })
  );

  const { data: categoryList } = useQuery(GET_CATEGORIES, () =>
    getCategories({
      selected,
    })
  );

  useEffect(() => {
    if (Array.isArray(categoryList?.results)) {
      setCategories(
        adaptCategories(
          categoryList.results.filter(
            (category) => category.parent === categoryId
          )
        )
      );
    }
  }, [categoryList, categoryId]);

  useEffect(() => {
    if (Array.isArray(productList?.results)) {
      setProducts(adaptProducts(productList.results));
    }
  }, [productList]);

  const handleClick = () => {
    navigate(`/search`);
  };

  const handleChange = (e) => {
    setSerchTerm(e.target.value);
  };

  return (
    <Layout headerVersion="v1" title={selected?.name}>
      <Warpper>
        <Title title={t("title.help")} fontSize="md" />
        <Input
          type="input"
          placeholder={t("placeholder.search")}
          Icon={MagnifyingGlassIcon}
          value={serchTerm}
          onClick={handleClick}
          onChange={handleChange}
        />
        {categories && (
          <TabsCarousel categories={categories} products={products} />
        )}

        {!categories && (
          <>
            <SkeletonAnimatedLoader />
            <SkeletonLoader />
          </>
        )}

        {categories?.map((category, key) =>
          filterProductsByCategory(products, category.id) ? (
            <Reveal key={category.title + key}>
              <Section title={category.title} uppercase>
                <Carousel
                  data={filterProductsByCategory(products, category.id)}
                >
                  <ProductCard />
                </Carousel>
              </Section>
            </Reveal>
          ) : null
        )}

        {/**
           *  <Section title="Featured services">
          <Carousel data={services}>
            <Card />
          </Carousel>
        </Section>
           */}
      </Warpper>
    </Layout>
  );
}
