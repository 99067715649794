import tw, { styled } from "twin.macro";

export const NavBarContainer = tw.nav`
flex items-center justify-center w-full md:w-[80%] lg:w-[60%] 
fixed bottom-0 z-20 bg-lightGray dark:bg-mediumGray `;
export const LinksList = styled.ul`
  ${tw`w-full flex justify-between items-center py-4 px-6 text-textGray`}

  .active {
    ${tw`text-secondary`}
  }
`;

export const NavBarLink = tw.li`cursor-pointer`;

export const NavBarIcon = tw.button`w-8 h-8 relative mt-2.5 `;

export const NavBarBadge = tw.div`h-5 w-5 absolute -top-3.5 -right-3 flex items-center justify-center
 rounded-lg text-xs font-medium text-white bg-secondary shadow-button`;
