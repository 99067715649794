const removeNil = (o) => {
    let out = {}
    for (const [key, value] of Object.entries(o)) {
        if(!!value) out[key]=value
    }
    delete out['_id']
    delete out['createdAt']
    delete out['updatedAt']
    delete out['__v']
    return out
};

export default removeNil