import { motion } from "framer-motion";
import tw, { styled } from "twin.macro";

export const fontSizes = {
  sm: tw`text-lg font-semibold`,
  md: tw`text-2xl font-semibold`,
  mdBold: tw`text-3xl font-bold`,
  mdLight: tw`text-2xl font-light`,
  lg: tw`text-[2.375rem] font-bold`,
};

export const Heading = styled(motion.h1)(({ color, level, uppercase }) => [
  //base styles
  tw`dark:text-primary truncate`,
    //dynamic color
    uppercase && tw`uppercase`,

  //styles based on level
  ({ fontSize }) => fontSizes[fontSize],

  //dynamic color
  color && `color: ${color};`,
  // adjust font wieght for lower levels
  level > 3 && tw`font-semibold`,
]);
