import { Routes, Route, useLocation } from "react-router-dom";
import NotFound from "./NotFound";

export default function NotFoundRoute() {
  const location = useLocation();
  return (
    <Routes key={location.pathname}>
      <Route index element={<NotFound />} />
    </Routes>
  );
}
