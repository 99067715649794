import tw, { styled } from "twin.macro";


export const Select = styled.select(({ Icon, isTextaria, disabled }) => [
  tw`my-2 w-full h-14 border-2 border-textGray border-solid rounded-xl px-4 py-2 bg-lightGray text-textGray
  dark:bg-darkGray text-lg outline-none
  `,
  Icon && tw`pl-14`,
  isTextaria && tw`w-full min-h-[12.5rem] pl-4 pt-4`,
  disabled && tw`cursor-not-allowed opacity-50`,
]);