import { useState, useEffect, useRef } from "react";
import StepWizard from "react-step-wizard";
import { useParams } from "react-router-dom";
import { DayPicker } from "react-day-picker";
import { useQuery, useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import removeNil from "lib/removeNil";
import countryList from "config/countryList";
import { GET_LOCATION_DETAILS } from "config/queries";
import { getLocationById, createCheckin} from "services";
import Confirmation from "./Confirmation";
import SignatureCanvas from "react-signature-canvas";
import Input from "components/Input";
import Button from "components/Button";
import Layout from "components/Layout";
import { Wrapper, FixedNavBtn, Astep } from "./CheckIn.styles";
import { Select } from "components/Form/Select";
import Title from "components/Title";
import { TitleWrapper } from "./CheckIn.styles";
import Upload from "./Upload";
import "filepond/dist/filepond.min.css";
import "react-day-picker/dist/style.css";

export default function CheckIn() {
  const signatureRef = useRef();
  const [formResult, setFormResult] = useState({
    firstname: "",
    name: "",
    nationality: "Morocco",
    arrival: new Date(),
    departure: new Date(),
    estimatedArrivalTime: "",
    flightNumber: "",
    signature: "",
    idScan: [],
    place: ""
  });

  const { t } = useTranslation();
  const { /*checkinId, */hotelId } = useParams();
  const [checkinSent, setCheckinSent] = useState(false);

  useEffect(() => {
    setFormResult({
      ...formResult,
      place: hotelId,
      status: 'checkin'
    });
    // eslint-disable-next-line
  }, [hotelId]);

  const { data: locationDetails } = useQuery(
    GET_LOCATION_DETAILS + hotelId,
    () =>
      getLocationById({
        placeId: hotelId,
      })
  );

  const onNewMutation = useMutation((data) => createCheckin(data), {
    onSuccess: () => {
      setCheckinSent(true);
    },
    onError: () => {
      toast.error(t("update.error"));
    },
  });

    const handleSubmit = () => {
    onNewMutation.mutate({
      ...removeNil(formResult)
    });
  };
   
  const handleMedia = (mediaUrl) => {
    if (!mediaUrl) return;
    setFormResult({ ...formResult, idScan: [...formResult.idScan, mediaUrl] });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormResult({
      ...formResult,
      [name]: value,
    });
  };

  function handleArrival(date) {
    setFormResult({ ...formResult, arrival: date })
  }

  function handleDeparture(date) {
    setFormResult({ ...formResult, departure: date })
  }

  return (
    <Layout
      hideNav={true}
      headerVersion="v3"
      title={t("checkin.title") + " at " + locationDetails?.name}
    >
      <Wrapper>
        {!checkinSent ? (
          <>
            <StepWizard
              nav={
                <NavigationBtns
                  disabled={!formResult?.email?.includes("@")}
                  handleSubmit={handleSubmit}
                />
              }
            >
              <Astep>
                <StyledTitle>{ t("checkin.personalInfo")}</StyledTitle>
                <Input
                  type="text"
                  placeholder={ t('form.firstname')}
                  onChange={handleInputChange}
                  isTextaria={true}
                  name="firstname"
                  value={formResult["fistname"]}
                />
                <Input
                  type="text"
                  placeholder={t('form.lastname')}
                  onChange={handleInputChange}
                  isTextaria={true}
                  name="name"
                  value={formResult["name"]}
                />

                <Input
                  type="email"
                  placeholder={t('form.email')}
                  onChange={handleInputChange}
                  isTextaria={true}
                  name="email"
                  value={formResult["email"]}
                />

                <Input
                  type="text"
                  placeholder={t('form.passeport')}
                  onChange={handleInputChange}
                  isTextaria={true}
                  name="passeport"
                  value={formResult["passeport"]}
                />

                <Select onChange={handleInputChange} name={"nationality"}>
                  <option value={null} disabled>
                    {t("form.nationality")}
                  </option>
                  {countryList.map((c, k) => (
                    <option value={c} key={c + k}>
                      {t(c)}
                    </option>
                  ))}
                </Select>
              </Astep>

              <Astep>
                <StyledTitle>{ t('arrival') }</StyledTitle>
                <DayPicker
                  firstDayOfWeek={1}
                  mode="single"
                  selected={formResult?.arrival}
                  onSelect={(date)=>handleArrival(date)}
                  disabled={{ before: new Date() }}
                />
              </Astep>

              <Astep>
                <StyledTitle>{ t('departure') }</StyledTitle>
                <DayPicker
                  firstDayOfWeek={1}
                  mode="single"
                  selected={new Date(formResult?.departure)}
                  onSelect={handleDeparture}
                  disabled={{ before: new Date() }}
                />
              </Astep>
              <Astep>
                <StyledTitle>{ t('form.estimatedArrivalTime')}</StyledTitle>
                <Input
                  type="time"
                  onChange={handleInputChange}
                  isTextaria={true}
                  name="estimatedArrivalTime"
                  value={formResult["estimatedArrivalTime"]}
                />
                <Input
                  type="text"
                  placeholder={t('form.flight')}
                  onChange={handleInputChange}
                  isTextaria={true}
                  name="flightNumber"
                  value={formResult["flightNumber"]}
                />
              </Astep>
              <Astep>
                <Upload
                  place={hotelId}
                  email={formResult?.email}
                  handleMedia={handleMedia}
                />
              </Astep>
              <Astep>
                <StyledTitle>{ t('form.signature')}</StyledTitle>
                <SignatureCanvas
                  ref={signatureRef}
                  penColor="green"
                  onEnd={() =>
                    setFormResult({
                      ...formResult,
                      signature: signatureRef.current
                        .getTrimmedCanvas()
                        .toDataURL("image/png"),
                    })
                  }
                  canvasProps={{
                    width: 250,
                    height: 200,
                    className: "sigCanvas",
                  }}
                />
              </Astep>
            </StepWizard>
          </>
        ) : (
          <Confirmation/>
        )}
      </Wrapper>
    </Layout>
  );
}

const NavigationBtns = ({
  nextStep,
  previousStep,
  totalSteps,
  currentStep,
  disabled = false,
  handleSubmit
}) => (
  <FixedNavBtn>
    {
      currentStep < totalSteps && 
       <Button
      size="large"
      label={"Next"}
      onClick={nextStep}
      disabled={disabled}
    />
  
    }

    {
      currentStep === totalSteps &&  <Button
      size="large"
      label={"Submit"}
      onClick={handleSubmit}
      disabled={disabled}
    />
    }

    
        <div onClick={previousStep} style={{ paddingTop: "20px" }}>
        {currentStep > 1 && <span>Previous</span>}
      </div>
    
   
  </FixedNavBtn>
);

const StyledTitle = ({ children }) => (
  <TitleWrapper>
    <Title fontSize="mdLight">{children}</Title>
  </TitleWrapper>
);
