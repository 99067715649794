import tw from "twin.macro";

export const Wrapper = tw.div`
  col-span-1 flex flex-col justify-center items-center
  rounded-[0.875rem] bg-[#FAFAFA] dark:bg-[#3A3947]
`;

export const Image = tw.img`
  w-full h-[8.813rem] object-cover rounded-[0.875rem]
`;

export const Name = tw.p`
  w-full px-5 pt-3 pb-6 rounded-b-[0.875rem]
  text-black dark:text-white text-base font-semibold capitalize text-center
`;
