import create from "zustand";
import { persist } from "zustand/middleware";
import { USER_INTERACTIONS } from "config/localStorage";

const useDark = create(
  persist(
    (set) => ({
      isDark: false,
      toggle: () => set((state) => ({ isDark: !state.isDark })),
    }),
    {
      name: USER_INTERACTIONS,
      getStorage: () => localStorage,
    }
  )
);

export default useDark;
