import { Player } from "@lottiefiles/react-lottie-player";
import lottieLoading from "./noOrdersLottie.json";

export default function Confirmation() {
  return (
    <Player
      autoplay
      loop
      src={lottieLoading}
      style={{ height: "100%", width: "100%" }}
    />
  );
}
