import tw from "twin.macro";

export const Wrapper = tw.div`
 flex flex-row justify-between items-center px-4 py-7
 text-lg text-center dark:text-primary dark:bg-darkGray
`;

export const Avatar = tw.img`
    w-12 h-12 rounded-2xl shadow-button
`;
