import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
//routes
import ProtectedRoute from "components/ProtectedRoute";
import NotFoundRoutes, { path as notFoundPath } from "./404";
import Home, { path as homePath } from "./home";
import Categories, { path as categoriesPath } from "./categories";
import Onboarding, { path as onboardingPath } from "./onboarding";
import Category, { path as categoryPath } from "./category";
import Details, { path as detailsPath } from "./details";
import Review, { path as reviewPath } from "./review";
import Order, { path as orderPath } from "./order";
import Search, { path as searchPath } from "./search";
import ScanQR, { path as scanQrPath } from "./scanQR";
import Store, { path as storePath } from "./store";
import useDark from "hooks/useDark";
import Checkout, { path as checkoutPath } from "./checkout";
import Track, { path as trackPath } from "./track";
import Nearby, { path as nearbyPath } from "./nearby";
import CheckIn, { newPath } from "./checkin";
import UpdateCheckin, { updatePath } from "./checkin";

import "react-toastify/dist/ReactToastify.css";

export default function RoutesComponent() {
  const isDark = useDark((state) => state.isDark);
  return (
    <div className={isDark ? "dark " : ""}>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
        theme="colored"
      />

      <BrowserRouter>
        <Routes>
          <Route path={onboardingPath} element={<Onboarding />} />
          <Route path={categoryPath} element={<Category />} />
          <Route path={categoriesPath} element={<Categories />} />
          <Route path={detailsPath} element={<Details />} />
          <Route path={reviewPath} element={<Review />} />
          <Route path={orderPath} element={<Order />} />
          <Route path={searchPath} element={<Search />} />
          <Route path={homePath} element={<Home />} />
          <Route path={scanQrPath} element={<ScanQR />} />
          <Route path={storePath} element={<Store />} />
          <Route path={checkoutPath} element={<Checkout />} />
          <Route path={trackPath} element={<Track />} />
          <Route path={nearbyPath} element={<Nearby />} />
          <Route path={newPath} element={<CheckIn />} />
          <Route path={updatePath} element={<UpdateCheckin />} />

          <Route element={<ProtectedRoute />}>
            {/**🔒 Protected routes bellow 🔒**/}

            {/**⚠️ End of Protected routes ⚠️**/}
          </Route>
          <Route path={notFoundPath} element={<NotFoundRoutes />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}
