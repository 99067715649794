import tw, { styled } from "twin.macro";

export const Warpper = styled.div(({ hideNav }) => [
  tw`relative min-h-screen dark:bg-primarybg transition-colors duration-700
  flex flex-col mx-auto pb-[4.625rem] overflow-x-hidden`,
  hideNav && tw`pb-0`,
]);

export const ChildrenWrapper = styled.div(({ padding }) => [
  tw`flex flex-col flex-1 gap-7 py-4 px-5 md:px-6 pt-7`,
  padding && tw`p-0`,
]);
