import { motion } from "framer-motion";
import tw from "twin.macro";

export const Wrapper = tw(motion.div)`
  flex flex-col gap-6 [&>button]:self-center
`;

export const ProductInfos = tw.section`
    w-full flex flex-col gap-4 
`;

export const Description = tw.p`
 text-base font-normal dark:text-textGray
`;

export const AddOns = tw.section`
  flex flex-col gap-1.5
`;
