const headerV2Data =  [
  {
    label: "Categories",
    link: "/categories",
    duration: 50,
  },
  {
    label: "Categories",
    link: "/categories",
    duration: 50,
  },
  {
    label: "Categories",
    link: "/categories",
    duration: 50,
  },
  {
    label: "Categories",
    link: "/categories",
    duration: 50,
  },
  {
    label: "Categories",
    link: "/categories",
    duration: 50,
  },
];

export default headerV2Data