import PropTypes from "prop-types";
import { Stars, StyledIcon, Wrapper } from "./Rating.styles";
import { StarIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
import Title from "components/Title";

function Rating({ text, rating, setRating }) {
  const [hover, setHover] = useState(undefined);

  const handClick = (index) => {
    setRating(index + 1);
  };
  const getColor = (index) => {
    let color = "currentColor";
    if (hover) {
      index < hover ? (color = "#FFC529") : (color = "currentColor");
    } else if (index < rating) {
      color = "#FFC529";
    } else {
      color = "currentColor";
    }

    return color;
  };
  return (
    <Wrapper>
      <Title title={text} fontSize="mdLight" />
      <span></span>
      <Stars>
        {Array.from(Array(5)).map((text, index) => (
          <StyledIcon
            key={index+'styledIcon'}
            onMouseEnter={() => setHover(index + 1)}
            onClick={() => handClick(index)}
            onMouseLeave={() => setHover(undefined)}
          >
            <StarIcon fill={getColor(index)} stroke="#FFC529" />
          </StyledIcon>
        ))}
      </Stars>
    </Wrapper>
  );
}

Rating.propTypes = {
  text: PropTypes.string,
};

export default Rating;
