import tw, { styled } from "twin.macro";

export const Wrapper = styled.div`
  ${tw`w-[92%] mx-auto flex flex-col p-5 gap-y-5
     bg-lightGray dark:bg-darkGray rounded-[1.125rem]`}
  span {
    ${tw`dark:text-textGray text-mediumGray font-bold text-[0.75rem]`}
  }
`;

export const ItemsInfoContainer = tw.div`
 flex flex-row justify-between w-full items-start
`;
export const Id = tw.p`
    text-secondary dark:text-yellow text-base font-bold 
`;
export const ItemsInfo = tw.div`
 flex flex-row gap-4 
`;
export const Image = tw.img`
    w-16 h-16 object-cover rounded-2xl
`;

export const Info = tw.div`
    flex flex-col dark:text-primary text-base
`;
export const ArrivalInfo = tw.div`
    flex flex-row w-full justify-between items-start
`;

export const TimeArrival = styled.div`
  ${tw`flex flex-col justify-start text-[2.5rem] last:leading-[2.45rem]  dark:text-primary`}
  p > span {
    ${tw`dark:text-primary font-bold text-base`}
  }
`;
export const TextArrival = tw.div`
  flex flex-col items-end dark:text-primary font-bold text-[0.875rem]
`;

export const Buttons = tw.div`
    flex flex-row w-full justify-around
`;
