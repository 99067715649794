import create from "zustand";
import { persist } from "zustand/middleware";
import { USER_INTERACTIONS } from "config/localStorage";
import toggle from "lib/arrayToggle";

const useFavorites = create(
  persist(
    (set, get) => ({
      favorites: [],
      toggleFavorites: (id) => {
        set((state) => ({
          favorites: toggle(state.favorites, id),
        }));
      },
    }),
    {
      name: USER_INTERACTIONS,
      getStorage: () => localStorage,
    }
  )
);

export default useFavorites;
