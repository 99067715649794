import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useParams, useNavigate } from "react-router-dom";
import { Reveal } from "react-reveal";
import Input from "components/Input";
import Section from "components/Section";
import Layout from "components/Layout";
import Loading from "./Loading";
import SkeletonAnimatedLoader from "components/SkeletonAnimatedLoader";
import SkeletonLoader from "components/SkeletonLoader";
import { Warpper, CatgoriesList } from "./Home.styles";
import {
  GET_PRODUCTS,
  GET_LOCATION_DETAILS,
  GET_CATEGORIES,
} from "config/queries";
import { getProducts, getLocationById, getCategories } from "services";
import { adaptCategories, adaptProducts } from "lib/dataAdapter";
import usePlace from "hooks/usePlace";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import Title from "components/Title";
import ParentCategoryCard from "components/ParentCategoryCard";

export default function App() {
  const { t } = useTranslation();
  let navigate = useNavigate();
  let { placeId } = useParams();
  const setSelectedPlace = usePlace((state) => state.setSelected);
  const [serchTerm, setSerchTerm] = useState();
  const [categories, setCategories] = useState(null);
  const [parentCategories, setParentCategories] = useState(null);

  const [products, setProducts] = useState(null);

  const { data: locationDetails, error } = useQuery(GET_LOCATION_DETAILS, () =>
    getLocationById({
      placeId,
    })
  );

  const { data: productList } = useQuery(GET_PRODUCTS, () =>
    getProducts({
      placeId,
    })
  );

  const { data: categoryList } = useQuery(GET_CATEGORIES, () =>
    getCategories({
      placeId,
    })
  );

  useEffect(() => {
    if (Array.isArray(categoryList?.results)) {
      setCategories(
        adaptCategories(categoryList.results.filter((a) => !!a.parent))
      );
      setParentCategories(
        adaptCategories(
          categoryList.results.filter((category) => category.parent === null)
        )
      );
    }
  }, [categoryList]);

  useEffect(() => {
    if (Array.isArray(productList?.results)) {
      setProducts(adaptProducts(productList.results));
    }
  }, [productList]);

  useEffect(() => {
    if (locationDetails) {
      setSelectedPlace({ ...locationDetails, _id: placeId });
    }
  }, [locationDetails, setSelectedPlace, placeId]);

  const handleClick = () => {
    navigate(`/search`);
  };

  const handleChange = (e) => {
    setSerchTerm(e.target.value);
  };

  useEffect(() => {
    if (error) {
      navigate("/scanqr");
    }
  }, [error, navigate]);

  return (
    <>
      {locationDetails?.name ? (
        <Layout headerVersion="v3" title={locationDetails?.name}>
          <Warpper>
            <Title title={t("title.help")} fontSize="md" />
            <Input
              type="input"
              placeholder={t("placeholder.search")}
              Icon={MagnifyingGlassIcon}
              value={serchTerm}
              onClick={handleClick}
              onChange={handleChange}
            />
            {!categories && (
              <>
                <SkeletonAnimatedLoader />
                <SkeletonLoader />
              </>
            )}
            <Reveal>
              <Section title={t("services")} uppercase>
                <CatgoriesList>
                  {parentCategories?.map((parentCategory) => (
                    <ParentCategoryCard
                      onClick={() =>
                        navigate(`/catgories/${parentCategory._id}`)
                      }
                      key={parentCategory.name}
                      name={parentCategory.name}
                      image={parentCategory.image}
                    />
                  ))}
                </CatgoriesList>
              </Section>
            </Reveal>

            {/**
           *  <Section title="Featured services">
          <Carousel data={services}>
            <Card />
          </Carousel>
        </Section>
           */}
          </Warpper>
        </Layout>
      ) : (
        <Loading />
      )}
    </>
  );
}
