import i18next from "i18next";

const language = i18next.language || window.localStorage.i18nextLng;

function getSafe(fn, defaultVal) {
  try {
    return fn();
  } catch (e) {
    return defaultVal;
  }
}

export default function ts(key, object) {
  return getSafe(() => object.dictionary[key][language]) || object[key];
}
