const bannedPageIds = [
    "288204",
    "5072011",
    "2232330",
    "14379377",
    "36922187",
    "44089963",
    "50096774",
    "50380534",
    "55588444"
]
  
export default bannedPageIds