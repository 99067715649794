import {  fetchit } from 'lib'

import {
    API_LOCATION
} from 'config/api'


export function getLocationById({ placeId }) {
    if(!placeId) return 
    return fetchit(API_LOCATION+'/'+placeId, null, 'GET')
}


export function geocode({ address, city, zipcode, country, accessToken }) {
    if(!address || !country || !city ) return false
    return fetchit(API_LOCATION+'/geocode?address='+address+'&zipcode='+zipcode+'&country='+country+'&city='+city, null, 'GET', accessToken)
}