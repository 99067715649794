import PropTypes from "prop-types";
import { useState } from "react";
import { Link } from "react-router-dom";
//components
import IconButton from "components/IconButton";
import Modal from "components/Modal";
//styles
import { Avatar, LinksList, ListItem, Wrapper } from "./Header_V2.styles";
//icons and data
import { Bars3BottomLeftIcon } from "@heroicons/react/24/outline";
import links from "./data";
import avatar from "images/avatar.svg";

function Header_V2({ title }) {
  const [isOpen, setIsOpen] = useState(false);
  //menu
  const hadnleClose = () => {
    setIsOpen(false);
  };
  const handleOpen = () => {
    setIsOpen(true);
  };

  return (
    <Wrapper>
      {!isOpen && (
        <IconButton
          aria-label="hamburger menu"
          onClick={handleOpen}
          Icon={Bars3BottomLeftIcon}
          IconColor="#FFF"
        />
      )}
      <p>{title}</p>
      <Avatar src={avatar} alt="your avatar" />
      <Modal isOpen={isOpen} closeModal={hadnleClose}>
        <LinksList>
          {links?.length &&
            links.map((item, index) => (
              <ListItem key={item.link + index}>
                <Link to={item.link}>{item.label}</Link>
              </ListItem>
            ))}
        </LinksList>
      </Modal>
    </Wrapper>
  );
}

Header_V2.propTypes = {
  title: PropTypes.string,
  buttonProps: PropTypes.shape(IconButton.propTypes),
};

export default Header_V2;
