import PropTypes from "prop-types";
//styles
import { Wrapper } from "./RatingTag.styles";
//icons
import { StarIcon } from "@heroicons/react/24/solid";

function RatingTag({ stars, peopleRated, variant = "small" }) {
  return (
    <Wrapper variant={variant}>
      <p>{stars}</p>
      <StarIcon width="1rem" color="#f59e0b" />
      <span>{`(${peopleRated}+)`}</span>
    </Wrapper>
  );
}

RatingTag.propTypes = {
  stars: PropTypes.number,
  peopleRated: PropTypes.number,
  variant: PropTypes.string,
};

export default RatingTag;
