function Clock({ width, height, color }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 8 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.32262 8.79266C3.09771 8.79273 2.87337 8.77012 2.65299 8.72519C2.22803 8.63814 1.82437 8.46827 1.46504 8.22527C0.930831 7.86463 0.512677 7.35681 0.261242 6.76333C0.17624 6.56236 0.111346 6.35347 0.0675094 6.13971C-0.0225032 5.69783 -0.0225032 5.24233 0.0675094 4.80045C0.154555 4.37549 0.324427 3.97183 0.56743 3.6125C0.928066 3.07829 1.43589 2.66014 2.02937 2.4087C2.23034 2.3237 2.43923 2.25881 2.65299 2.21497C3.09487 2.12496 3.55037 2.12496 3.99225 2.21497C4.41721 2.30202 4.82087 2.47189 5.1802 2.71489C5.71441 3.07553 6.13256 3.58335 6.384 4.17683C6.469 4.3778 6.53389 4.58669 6.57773 4.80045C6.66624 5.23529 6.66763 5.68337 6.58182 6.11875C6.50822 6.49324 6.36983 6.852 6.17288 7.17891C5.87793 7.67143 5.46026 8.07903 4.96068 8.36187C4.46111 8.64472 3.89671 8.79315 3.32262 8.79266ZM4.46712 3.91358C4.41328 3.91353 4.35996 3.92412 4.31023 3.94474C4.26049 3.96535 4.21531 3.99558 4.17729 4.0337L3.01592 5.19354C2.93898 5.27061 2.89581 5.37509 2.89591 5.48399C2.89601 5.59289 2.93936 5.69729 3.01643 5.77422C3.0935 5.85116 3.19798 5.89433 3.30688 5.89423C3.41578 5.89414 3.52018 5.85078 3.59712 5.77371L4.17729 5.19354L4.75746 4.61388C4.81546 4.55676 4.85509 4.48365 4.87129 4.40388C4.88749 4.32411 4.87951 4.24133 4.84839 4.16612C4.81726 4.09091 4.7644 4.0267 4.69657 3.98171C4.62874 3.93672 4.54902 3.913 4.46763 3.91358H4.46712Z"
        fill="url(#paint0_linear_583_338)"
      />
      <path
        d="M1.69629 0.53316C1.69629 0.417925 1.74207 0.307411 1.82355 0.225928C1.90503 0.144445 2.01555 0.098668 2.13078 0.098668L4.73773 0.098668C4.85296 0.098668 4.96348 0.144445 5.04496 0.225928C5.12645 0.307411 5.17222 0.417925 5.17222 0.53316C5.17222 0.648394 5.12645 0.758909 5.04496 0.840392C4.96348 0.921875 4.85296 0.967651 4.73773 0.967651L2.13078 0.967651C2.01555 0.967651 1.90503 0.921875 1.82355 0.840392C1.74207 0.758909 1.69629 0.648394 1.69629 0.53316V0.53316Z"
        fill="url(#paint1_linear_583_338)"
      />
      <path
        d="M6.15622 1.34451C6.24039 1.26035 6.35454 1.21306 6.47357 1.21306C6.5926 1.21306 6.70676 1.26035 6.79092 1.34451L7.36418 1.91777C7.44835 2.00194 7.49564 2.11609 7.49564 2.23512C7.49564 2.35415 7.44835 2.46831 7.36418 2.55248C7.28002 2.63664 7.16586 2.68393 7.04683 2.68393C6.9278 2.68393 6.81365 2.63664 6.72948 2.55248L6.15622 1.97922C6.07205 1.89505 6.02477 1.7809 6.02477 1.66187C6.02477 1.54283 6.07205 1.42868 6.15622 1.34451Z"
        fill="url(#paint2_linear_583_338)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_583_338"
          x1="3.32258"
          y1="2.14746"
          x2="3.32258"
          y2="8.79266"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FE724C" />
          <stop offset="1" stopColor="#FCA892" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_583_338"
          x1="1.69629"
          y1="0.53316"
          x2="5.17222"
          y2="0.53316"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FE724C" />
          <stop offset="1" stopColor="#FCA892" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_583_338"
          x1="6.15622"
          y1="1.34451"
          x2="7.36418"
          y2="2.55248"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FE724C" />
          <stop offset="1" stopColor="#FCA892" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Clock;
