import {  fetchit } from 'lib'

import {
    API_ORDER
} from 'config/api'

export function createOrder(data, accessToken) {
    return fetchit(API_ORDER, data, 'POST', accessToken)
}

export function getOrderById({ orderId, accessToken }) {
    if(!orderId) return
    return fetchit(API_ORDER+'/'+orderId, null, 'GET', accessToken)
}