import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import formatDistance from "date-fns/formatDistance";
import { useTranslation } from "react-i18next";
import { getOrderById } from "services";
import Layout from "components/Layout";
import Title from "components/Title";
import Loading from "../home/Loading";

const orderStatuses = ["pending", "received", "completed", "shipped", "cancelled"];

function Track() {
  const { t } = useTranslation();
  const { orderId } = useParams();

  const getOrderDetails = useQuery(
    orderId,
    () =>
      getOrderById({
        orderId,
      }),
    {
      refetchInterval: 5000,
    }
  );

  const serverStatusUpdatesArray = useMemo(()=>getOrderDetails?.data?.updates?.map(a => a.status) || [], [getOrderDetails]) 

  return (
    <Layout headerVersion="v3" title={t("order.status")}>
      <Title level={3} fontSize="sm">
        {t('track.waitTime')}
      </Title>
     
      {(!getOrderDetails?.data?.updates ||
        !getOrderDetails?.data?.updates[0] ||
        !getOrderDetails?.data?.updates[0].status) && <Loading />}

      {orderStatuses.map((o) => {
        return (
          <Step
            status={o}
            timestamp={getOrderDetails?.data?.updates?.filter(a => a.status===o)[0]?.timestamp}
            disable={(serverStatusUpdatesArray.indexOf(o) === -1) && (o !== 'pending')}
          />
      )})}

    </Layout>
  );
}
export default Track;

const Step = ({ status, timestamp, disable }) => (
  <div style={disable ? {opacity:0.1} : {}}>
     <hr />
    <Title level={1} fontSize="lg" uppercase>
      {status}
    </Title>
    <Title level={3} fontSize="sm">
    {timestamp &&
      formatDistance(new Date(timestamp), new Date(), { addSuffix: true })}
    </Title>
   
  </div>
);
