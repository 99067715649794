import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import useCart from "hooks/useCart";
import useUser from "hooks/useUser";
import useOrder from "hooks/useOrders";
import Layout from "components/Layout";
import Input from "components/Input";
import NoOrders from "../order/NoOrders";
import usePlace from "hooks/usePlace";
import { createOrder } from "services";
import { createSchedule } from "services/schedule";
import Button from "components/Button";
import { Spacing } from "components/Layout/Spacing";

function Order() {
  const navigate = useNavigate();
  const user = useUser((state) => state.user);
  const { t } = useTranslation();
  const [roomNbr, setRoomNbr] = useState();
  const mycart = useCart((state) => state.cartContent);
  const clearCart = useCart(state=> state.clearCart)
  const selected = usePlace((state) => state.selected);
  const addOrder = useOrder((state) => state.addOrder);

  const onNewMutation = useMutation((data) => createOrder(data), {
    onSuccess: (data) => {
      if (data?._id) {
        addOrder(data._id);
        clearCart()
        navigate("/track/" + data._id);
      }
    },
    onError: () => {
      toast.error(t("update.error"));
    },
  });

  const onNewScheduleMutation = useMutation(
    (data) => createSchedule(data),
    {
      onSuccess: () => {
        //toast.success(t("update.success"));
      },
      onError: () => {
        toast.error(t("update.error"));
      },
    }
  );

  function handleCreateOrder() {
    let scheduleItems = []

    mycart.forEach((item) => {
      if(item.schedule) {
        scheduleItems.push({
          ...item.schedule,
          room: roomNbr,
          product: item.product,
          comment: item.note
        })
      }
    });

    let orderObject = {
      place: selected._id,
      room: roomNbr,
    };

    if (user?._id) {
      orderObject.customer = user._id;
    }

    if (user?.user?._id) {
      orderObject.customer = user.user._id;
    }

    const orderItems = mycart.map((item) => {
      return {
        product: item.product,
        department: item.department,
        note: item.note
      };
    });

    orderObject.orderItem = orderItems;

    onNewMutation.mutate({
      ...orderObject,
    });

    if (scheduleItems.length) {
      scheduleItems.forEach((schedule) => {
        onNewScheduleMutation.mutate(schedule)
      })
    }
  }

  function handleRoomInput(e) {
    setRoomNbr(e.target.value);
  }
  return (
    <Layout headerVersion="v1">
      {!mycart || (!mycart.length && <NoOrders />)}
      {mycart?.length !== 0 && (
        <div style={{ textAlign: "center" }}>
          <Spacing bottom={152} />
          <Input
            type="number"
            name="roomNumber"
            onChange={handleRoomInput}
            placeholder={t("enter.room.number")}
          />
          <Spacing bottom={22} />
          <Button
            size="large"
            label={t("proceed.checkout")}
            color="secondary"
            textColor="primary"
            onClick={handleCreateOrder}
            disabled={!roomNbr}
          />
        </div>
      )}
    </Layout>
  );
}

export default Order;
