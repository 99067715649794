import { MISSING_IMG_PLACE_HOLDER, UPLOAD_CDN } from "config/api";
import ts from "./translateString";

export function adaptCategories(serverSideCategories) {
    if(!Array.isArray(serverSideCategories)) return 
    return serverSideCategories.map(c => {
        return {
            ...c,
            title:  ts("name", c),
            id: c._id,
            image: (c.media && c.media[0] && c.media[0].location) ? 
            UPLOAD_CDN+c.media[0].filename : MISSING_IMG_PLACE_HOLDER
         }
    })
}

export function adaptProducts(serverSideProducts) {
    if(!Array.isArray(serverSideProducts)) return 
    return serverSideProducts.map(c => {
        return {
            ...c,
            title: ts("name", c),
            id: c._id,
            categories: c.categories,
            imageSrc: (c.media && c.media[0] && c.media[0].filename) ? 
            UPLOAD_CDN+c.media[0].filename : MISSING_IMG_PLACE_HOLDER,
            description: ts("description", c),
           
            price: (c.currency && c.price) ? c.price+' '+c.currency : null
         }
    })
}