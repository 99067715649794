import React from "react";
import PropTypes from "prop-types";
import { Warpper } from "./Section.styles";
import Title from "components/Title";

function Section({ title, children, uppercase }) {
  return (
    <Warpper>
      <Title level={2} title={title} fontSize="sm" uppercase />
      {children}
    </Warpper>
  );
}

Section.propTypes = {
  title: PropTypes.string,
};

export default Section;
