/**
 * Add or remove an element from array
 * @param {*} array
 * @param {*} value
 */
export default function toggle(array, value) {
  const index = array.indexOf(value);
  if (index === -1) {
    array.push(value);
  } else {
    array.splice(index, 1);
  }
  return array;
}
