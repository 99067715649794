import { Link } from "react-router-dom";
import { Player } from "@lottiefiles/react-lottie-player";
import { useTranslation } from "react-i18next";
import Layout from "components/Layout";
import Button from "components/Button";
import notfound from "./notfound.json";

export default function Confirmation() {
  const { t } = useTranslation();
  return (
    <Layout>
      <Player
        autoplay
        loop
        src={notfound}
        style={{ height: "50vh", width: "100%" }}
      />

      <Link to={"/scanqr"} style={{textAlign:'center'}}>
        <Button label={t("scan.qr")} />
      </Link>
    </Layout>
  );
}
