import tw, { styled } from "twin.macro";

export const InputContainer = tw.div`relative w-full`;
export const StyledInput = styled.input(({ Icon, isTextaria, disabled }) => [
  tw`w-full h-14 border-2 border-textGray border-solid rounded-xl px-4 py-2 my-2 bg-lightGray text-textGray
  dark:bg-darkGray text-lg outline-none
  `,
  Icon && tw`pl-14`,
  isTextaria && tw`w-full min-h-[12.5rem] pl-4 pt-4`,
  disabled && tw`cursor-not-allowed opacity-50`,
]);

export const StyledTextArea = styled.textarea (({ disabled }) => [
  tw`w-full my-2 h-56 border-2 border-textGray border-solid rounded-xl px-4 py-2 bg-lightGray text-textGray
  dark:bg-darkGray text-lg outline-none 
  `,
  disabled && tw`cursor-not-allowed opacity-50`,
]);

export const SyledIcon = tw.div`absolute left-0 top-0 m-4 w-6 h-6 text-textGray`;
