import { motion } from "framer-motion";
import tw from "twin.macro";

export const Wrapper = tw(motion.div)`
  flex flex-col gap-8
`;

export const Subtitle = tw.p`
 text-xl font-normal dark:text-textGray
`;

export const Sorting = tw.div`
  flex flex-row justify-between max-w-max gap-2 items-center
`;

export const ProductList = tw.div`
    w-full flex flex-col gap-5 items-center justify-center 
`;
