import { fontSizes, Heading } from "./Title.styles";
import PropTypes from "prop-types";

function Title({ title, level = "1", color, fontSize, children, uppercase }) {
  return (
    <Heading as={`h${level}`} color={color} fontSize={fontSize} uppercase={uppercase}>
      {title ? title : children}
    </Heading>
  );
}

Title.propTypes = {
  title: PropTypes.string,
  level: PropTypes.number,
  color: PropTypes.string,
  fontSize: PropTypes.oneOf(Object.keys(fontSizes)),
};

export default Title;
