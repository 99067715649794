import { Routes, Route, useLocation } from "react-router-dom";
import Checkout from "./Checkout";

export default function ScanQRRoute() {
  const location = useLocation();
  return (
    <Routes key={location.pathname}>
      <Route index element={<Checkout />} />
    </Routes>
  );
}
