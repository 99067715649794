import styled from 'styled-components';

/**
 * Adds margins to UI, using theme spacing options
 *
 * @param {string} top
 * @param {string} right
 * @param {string} bottom
 * @param {string} left
 * @param {boolean} inline, converts block element to inline block
 */
 export const Spacing = styled.div`
 ${(p) => p.top && `margin-top: ${p.top}px`};
 ${(p) => p.right && `margin-right: ${p.right}px`};
 ${(p) => p.bottom && `margin-bottom: ${p.bottom}px`};
 ${(p) => p.left && `margin-left: ${p.left}px`};
 ${(p) => p.inline && `display: inline-block;`}

`;