function filterProductsByCategory(products, category) {
  if (Array.isArray(products) && category) {
    const filteredProducts = products.filter(
      (product) => product?.visible && product?.categories?.includes(category)
    );
    if (filteredProducts.length > 0) return filteredProducts;
  }

  return null;
}

export default filterProductsByCategory;
