import {  fetchit } from 'lib'

import {
    API_CHECKIN
} from 'config/api'


export function createCheckin(data) {
    return fetchit(API_CHECKIN, data, 'POST', null)
}
