import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import PropTypes from "prop-types";

function Carousel({ children, data }) {
  return (
    <Swiper className="w-full h-full" spaceBetween={20} slidesPerView="1.2">
      {data &&
        data.map((item) => (
          <SwiperSlide key={item.id}>
            {React.cloneElement(children, { ...item })}
          </SwiperSlide>
        ))}
    </Swiper>
  );
}

Carousel.propTypes = {
  data: PropTypes.array,
};

export default Carousel;
