import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { TabContainer, TabImage, TabTitle } from "./Tab.styles";

function Tab({ title, image, id }) {
  return (
    <Link to={`/cat/${id}`}>
      <TabContainer id={id}>
        <TabImage src={image} alt={title} />
        <TabTitle>{title}</TabTitle>
      </TabContainer>
    </Link>
  );
}

Tab.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func,
};

export default Tab;
