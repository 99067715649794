import create from 'zustand'
import { persist } from 'zustand/middleware'
import omit from 'lodash-es/omit'
import {  APP_LOCATIONS } from 'config/localStorage'

const usePlace = create(
  persist(
    (set, get) => ({
      selected: null,
      setSelected: (data) => set({ selected: data }),
      deleteSelected: () => set((state) => omit(state, ['selected']), true),
    }),
    {
      name: APP_LOCATIONS,
      getStorage: () => localStorage,
    }
  )
)

export default usePlace
