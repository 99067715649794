import PropTypes from "prop-types";
import useCart from "hooks/useCart";
//components
import Header from "components/Header";
import NavBar from "components/NavBar";
//styles
import { ChildrenWrapper, Warpper } from "./Layout.styles";
//helpers
import { links } from "./data";
import HeaderV2 from "components/Header_V2";
import HeaderV3 from "components/HeaderV3";

function Layout({
  children,
  headerVersion,
  hideNav = false,
  hideHeader = false,
  title,
}) {
  const mycart = useCart((state) => state.cartContent);

  return (
    <Warpper hideNav={hideNav}>
      {!hideHeader && headerVersion === "v1" && <Header title={title} />}
      {!hideHeader && headerVersion === "v2" && <HeaderV2 title={title} />}
      {!hideHeader && headerVersion === "v3" && <HeaderV3 title={title} />}
      <ChildrenWrapper hideHeader={hideHeader}>{children}</ChildrenWrapper>
      {!hideNav && <NavBar links={links({ shoppingBadge: mycart.length })} />}
    </Warpper>
  );
}

Layout.propTypes = {
  hideHeader: PropTypes.bool,
  hideNav: PropTypes.bool,
};

export default Layout;
