// import { path as homePath } from "routes/home";
import {
  HomeIcon, 
  MapIcon,
  QrCodeIcon,
  ShoppingBagIcon
} from "@heroicons/react/24/solid";

export const links = ({shoppingBadge=0})=>[
  {
    name: "QR",
    link: "/",
    Icon: QrCodeIcon,
    badge: 0,
  },
  {
    name: "Home",
    link: "/store",
    Icon: HomeIcon,
    badge: 0,
  },
  {
    name: "nearby",
    link: "/nearby",
    Icon: MapIcon,
    badge: 0,
  },
  {
    name: "Order",
    link: "/order",
    Icon: ShoppingBagIcon,
    badge: shoppingBadge,
  },
];
