import { motion } from "framer-motion";
import tw, { styled } from "twin.macro";

export const Wrapper = tw(motion.div)`
    flex min-h-screen w-full md:w-[80%] lg:w-[60%] flex-col dark:bg-primarybg  
    [&>button]:self-center p-5 justify-around  mx-auto
`;

export const Image = styled.div`
  ${tw`w-full rounded-full`},
  &:after {
    position: relative;
    content: "";
    display: block;
    padding-bottom: 50%;
  }
  img {
    ${tw`absolute inset-0 w-full h-full object-fill object-center  rounded-full`}
  }
`;
export const Title = tw.h1`
 text-black dark:text-primary text-4xl relative 

 before:absolute before:content before:w-[10%] before:h-1 before:bg-[#FFC529] before:rounded-full
 before:-top-5 before:left-[50%] before:origin-center before:translate-x-[-50%]
`;
