import {
    Navigate,
  Outlet,
  useLocation 
} from 'react-router-dom';
import useUser from "hooks/useUser"
import useCurrentPlace from "hooks/usePlace"
import { AUTH_PATH, LOCATION_PATH } from "config/paths"
  
export default function ProtectedRoute({ redirectPath = AUTH_PATH, children }) {
  const location = useLocation();
  const user = useUser(state => state.user)
  const currentPlace = useCurrentPlace(state => state.selected)

    if (!user || !user.user ) {
      return <Navigate to={redirectPath} replace />;
  }

  if (!currentPlace && (LOCATION_PATH !== location.pathname)) {
    return <Navigate to={'/locations'} replace />;
  }
    return children ? children : <Outlet />;
};
