import { NavLink } from "react-router-dom";
import {
  LinksList,
  NavBarBadge,
  NavBarContainer,
  NavBarIcon,
  NavBarLink,
} from "./NavBar.styles";

function NavBar({ links }) {
  return (
    <NavBarContainer>
      <LinksList>
        {links?.length > 0 &&
          links?.map(({ Icon, ...item }) => (
            <NavLink to={item.link} key={item.link}>
              <NavBarLink>
                <NavBarIcon>
                  {Icon && <Icon />}
                  {item.badge > 0 && <NavBarBadge>{item.badge}</NavBarBadge>}
                </NavBarIcon>
              </NavBarLink>
            </NavLink>
          ))}
      </LinksList>
    </NavBarContainer>
  );
}

NavBar.defaultProps = {
  Links: [],
};

export default NavBar;
