import tw, { styled } from "twin.macro";

export const ButtonContainer = styled.button(
  ({ Icon, color, textColor, disabled, size}) => [
    size === "small" ? tw`w-[30%] text-sm shadow-button rounded-full  py-2 dark:text-primary` : tw` w-[70%] max-w-[15.5rem] rounded-full py-4 border-0 
    text-lg text-primary text-center  cursor-pointer bg-secondary dark:bg-mediumGray shadow-button dark:shadow-none`,
    Icon && tw`w-auto flex items-center justify-start py-2 px-4 pr-5`,
    color && `background-color: ${color};`,
    textColor && `color: ${textColor};`,
    disabled && tw`bg-[#b4a7a7] cursor-not-allowed opacity-20`,
    size === "large" && tw`px-10 py-5`,
  ]
);

export const ButtonIcon = tw.div`flex items-center justify-center w-10 h-10 rounded-full bg-primary text-secondary mr-4`;
export const StyledIcon = tw.span`w-5 h-5`;
