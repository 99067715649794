import { useState, useEffect } from "react";
import { QrReader } from "react-qr-reader";
import { useQuery } from "react-query";
import Layout from "components/Layout";
import { useNavigate } from "react-router-dom";
import { GET_LOCATION_DETAILS } from "config/queries";
import { getLocationById } from "services";

const constraints = {
  facingMode: "environment",
};

function getLastUrlSegment(url) {
  if (!url) return;
  return new URL(url).pathname.split("/").filter(Boolean).pop();
}

export default function Confirmation() {
  const navigate = useNavigate();
  const [placeId, setPlaceId] = useState(null);

  const { data: locationDetails } = useQuery(
    GET_LOCATION_DETAILS + placeId,
    () =>
      getLocationById({
        placeId,
      })
  );

  useEffect(() => {
    if (placeId && locationDetails?.name) {
      navigate("/h/" + placeId);
    }
  });

  return (
    <Layout>
      <QrReader
        constraints={constraints}
        onResult={(result, error) => {
          if (!!result) {
            setPlaceId(getLastUrlSegment(result?.text));
          }

          if (!!error) {
            console.info(error);
          }
        }}
        style={{ width: "100%" }}
      />
    </Layout>
  );
}
