import { useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import removeNil from "lib/removeNil";
import { GET_LOCATION_DETAILS } from "config/queries";
import { getLocationById, createReview } from "services";
import Confirmation from './Confirmation'
import ImageCard from "components/ImageCard";
import Rating from "components/Rating";
import Input from "components/Input";
import Button from "components/Button";
import Layout from "components/Layout";
import { Form, Wrapper, Spacing } from "./Review.styles";
import Title from "components/Title";

const REVIEW_KPIs = [
  "staff",
  "facilities",
  "cleanliness",
  "comfort",
  "valueForMoney",
  "location",
  "wifi",
];

const initialReviewState = REVIEW_KPIs.reduce(
  (a, v) => ({ ...a, [v]: null }),
  {}
);

export default function Review() {
  const { t } = useTranslation();
  const { placeId } = useParams();
  const [message, setMessage] = useState("");
  const [reviewSent, setReviewSent] = useState(false);
  const [rating, setRating] = useState(initialReviewState);
  const [positive, setPositive] = useState(true)

  const { data: locationDetails } = useQuery(GET_LOCATION_DETAILS, () =>
    getLocationById({
      placeId,
    })
  );

  const onNewMutation = useMutation((data) => createReview(data), {
    onSuccess: () => {
      setReviewSent(true);
    },
    onError: () => {
      toast.error(t("update.error"));
    },
  });

  const handleChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (event, review) => {
    event.preventDefault();
    onNewMutation.mutate({
      ...removeNil(review),
      place: placeId,
    });
  };

  const handleRaging = (nbr, kpi) => {
    let newRating = {};
    newRating[kpi] = nbr;
    if (nbr <= 2.5) {
      setPositive(false)
    }
    setRating({ ...rating, ...newRating });
  };

  return (
    <Layout hideNav={true}>
      <Wrapper>
        {!reviewSent ? (
          <>
            <ImageCard
              contain
              imageSrc={locationDetails?.logo}
              alt={locationDetails?.name}
              id={placeId}
              disableFav
            />

            <Title fontSize={"md"} level={1}>
              {t("review.title")}
            </Title>

            <Spacing />
            {REVIEW_KPIs.map((r) => (
              <Rating
                key={t(r) + 10}
                text={`How was the ${t(r)}?`}
                rating={rating[r]}
                setRating={(newRating) => handleRaging(newRating, r)}
              />
            ))}
            <Spacing />
            <Form onSubmit={(e) => handleSubmit(e, { message, ...rating })}>
              <Input
                type="textarea"
                placeholder="Optional comment"
                value={message}
                onChange={handleChange}
                isTextaria={true}
              />
              <Button
                size="large"
                label={"Submit"}
                disabled={!rating.staff || !rating.comfort}
              />
            </Form>
          </>
        ) : (
            <Confirmation positive={ positive } bookingUrl={locationDetails.bookingurl} />
        )}
      </Wrapper>
    </Layout>
  );
}
