import tw from "twin.macro";

export const Wrapper = tw.div`
 flex flex-col items-start min-w-[90%] mx-auto mb-4 bg-lightGray text-black rounded-[1.15rem]
 dark:bg-darkGray dark:text-primary
`;

export const Price = tw.span`
px-4 py-1 text-2xl bg-primary  dark:text-black rounded-[7.6rem] shadow-sm
`;
export const Description = tw.div`
    flex flex-col justify-center my-6 pl-4 
    font-semibold text-lg dark:text-primary
`;

export const Ingredients = tw.ul`
    flex flex-row justify-center mt-2.5 text-[0.9rem] font-normal dark:text-textGray
`;

export const Ingredient = tw.li`
    after:content[','] last:before:content['and '] last:after:content['.'] mr-2 line-height[0px]
`;
