
export function toHoursAndMinutes(totalMinutes) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `🚶 ${hours}h${minutes > 0 ? `${Math.round(minutes)}m walk ` : ''}`;
  }
  
export function meterDistanceToTravelMinutes(distance) {
    if(!distance) return null
    const realDistanceApprox = distance * 1.3
    const speedPerMeter = 1.4 //1.4m per second
    return toHoursAndMinutes((realDistanceApprox/speedPerMeter)/60)
}
  