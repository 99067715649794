import {  fetchit } from 'lib'

import {
    API_REVIEW
} from 'config/api'


export function createReview(data) {
    return fetchit(API_REVIEW, data, 'POST', null)
}
