import { ButtonContainer, StyledIcon } from "./IconButton.styles";
import PropTypes from "prop-types";

function IconButton({
  rounded,
  bgColor,
  IconColor,
  Icon,
  disabled,
  onClick,
  padding,
  IconWidth,
}) {
  return (
    <ButtonContainer
      rounded={rounded}
      bgColor={bgColor}
      disabled={disabled}
      onClick={onClick}
      padding={padding}
    >
      <StyledIcon>
        <Icon width={IconWidth} color={IconColor} />
      </StyledIcon>
    </ButtonContainer>
  );
}

IconButton.defaultProps = {
  color: "#fafafa",
  disabled: false,
  rounded: false,
  Icon: null,
  onClick: () => {},
};

IconButton.propTypes = {
  padding: PropTypes.string,
  rounded: PropTypes.bool,
  color: PropTypes.string,
  IconColor: PropTypes.string,
  IconWidth: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

export default IconButton;
