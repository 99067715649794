import React from "react";
import { FilePond } from "react-filepond";
import { useTranslation } from "react-i18next";
import { API_UPLOAD } from "config/api";

export default function AssetsComponent({
  place,
  email,
  handleMedia
}) {
  const { t } = useTranslation();

  function invalidateQuery(file) {
    console.log(file)
    //queryClient.invalidateQueries(GET_MEDIAS + place);
  }

  return (
    <div style={{ width: "400px" }}>
      <FilePond
        onprocessfile={invalidateQuery}
        allowMultiple={true}
        maxFiles={6}
        server={{
          process: {
            onload: (response) => {
              try {
                handleMedia(JSON.parse(response).location)
              } catch (error) {
                console.error(error)
              }
            
            },
          },
          url: `${API_UPLOAD}/${place}/${email}`}}
        name="files"
        labelIdle={t("form.upload")}
        credits={false}
      />
      </div>
  );
}
