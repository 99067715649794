import {  fetchit } from 'lib'

import {
    API_PRODUCT
} from 'config/api'

export function getProducts({ placeId }) {
    if(!placeId) return
    return fetchit(API_PRODUCT+'/?limit=500&place='+placeId, null, 'GET')
}

export function getProductById({ productId }) {
    if(!productId) return
    return fetchit(API_PRODUCT+'/'+productId, null, 'GET')
}