import tw, { styled } from "twin.macro";

export const ButtonContainer = styled.button(
  ({ bgColor, IconColor, disabled, rounded, padding }) => [
    tw` rounded-xl p-2  border-0 cursor-pointer flex justify-center items-center flex-shrink-0
        bg-[#f2f2f2] dark:bg-mediumGray  dark:text-white`,
    bgColor && `background-color: ${bgColor};`,
    IconColor && `color: ${IconColor};`,
    disabled && tw`bg-[#b4a7a7] cursor-not-allowed`,
    rounded && tw`rounded-full`,
    padding && `padding: ${padding}px;`,
  ]
);

export const StyledIcon = tw.span`w-8 h-8 `;
