import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Reveal } from 'react-reveal'
import { useNavigate } from "react-router-dom";
import Input from "components/Input";
import Carousel from "components/Carousel";
import TabsCarousel from "components/TabsCarousel";
import Section from "components/Section";
import SkeletonAnimatedLoader from "components/SkeletonAnimatedLoader";
import Layout from "components/Layout";
import ProductCard from "components/ProductCard";
import SkeletonLoader from 'components/SkeletonLoader'
import { Warpper } from "./Store.styles";
import {
  GET_PRODUCTS,
  GET_CATEGORIES,
} from "config/queries";
import { getProducts,  getCategories } from "services";
import { adaptCategories, adaptProducts } from "lib/dataAdapter";
import usePlace from "hooks/usePlace";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import Title from "components/Title";

export default function App() {
  const { t } = useTranslation();
  let navigate = useNavigate();

  const selected = usePlace((state) => state.selected);
  const [serchTerm, setSerchTerm] = useState();
  const [categories, setCategories] = useState(null);
  const [products, setProducts] = useState(null);


  const { data: productList, error } = useQuery(GET_PRODUCTS, () =>
    getProducts({
      placeId: selected._id,
    })
  );

  const { data: categoryList } = useQuery(GET_CATEGORIES, () =>
    getCategories({
      placeId: selected._id,
    })
  );

  useEffect(() => {
    if (Array.isArray(categoryList?.results)) {
      setCategories(
        adaptCategories(categoryList.results.filter((a) => !!a.parent))
      );
    }
  }, [categoryList]);

  useEffect(() => {
    if (Array.isArray(productList?.results)) {
      setProducts(adaptProducts(productList.results));
    }
  }, [productList]);

  useEffect(() => {
    if (error) {
      navigate("/");
    }
  }, [error, navigate]);

  const handleClick = () => {
    navigate(`/search`);
  };

  const handleChange = (e) => {
    setSerchTerm(e.target.value);
  };

  

  function filterProductsByCategory(products, category) {
    if (Array.isArray(products) && category) {
      const filtred = products?.filter(
        (p) => p?.categories?.indexOf(category?.id) > -1
      );
      if (filtred.length) {
        return filtred;
      }
    }

    return null;
  }

  return (
    <>
    {selected?.name ? <Layout headerVersion="v3" title={selected?.name}>
      <Warpper>
        <Title title={t("title.help")} fontSize="md" />
        <Input
          type="input"
          placeholder={t("placeholder.search")}
          Icon={MagnifyingGlassIcon}
          value={serchTerm}
          onClick={handleClick}
          onChange={handleChange}
          />
          {
            !categories && <><SkeletonAnimatedLoader /><SkeletonLoader/></>
          }
        {categories && <TabsCarousel categories={categories} />}

       

        {categories &&
          categories.map((category, key) => {
            if (filterProductsByCategory(products, category)) {
              return (
                <Reveal key={category.title + key} >
                <Section title={category.title} >
                  <Carousel
                    data={filterProductsByCategory(products, category)}
                  >
                    <ProductCard />
                  </Carousel>
                  </Section>
                  </Reveal>
              );
            }
            return null;
          })}

        {/**
           *  <Section title="Featured services">
          <Carousel data={services}>
            <Card />
          </Carousel>
        </Section>
           */}
      </Warpper>
    </Layout> : <SkeletonLoader />}
    </>
    
  );
}
