import React from "react";
import { PropTypes } from "prop-types";
import { InputContainer, SyledIcon, StyledInput } from "./Input.styles";

// const SplitText = function ({ text }) {

const Input = React.forwardRef(
  (
    {
      placeholder,
      value,
      onChange,
      onClick,
      Icon,
      type,
      name,
      disabled,
      label,
    },
    ref
  ) => {
    return (
      <InputContainer onClick={onClick}>
        <StyledInput
          ref={ref}
          aria-label={label}
          as={"input"}
          type={type ? type : "text"}
          name={name}
          disabled={disabled}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          isTextaria={type === "textarea"}
          Icon={Icon}
        />
        {Icon && (
          <SyledIcon>
            <Icon />
          </SyledIcon>
        )}
      </InputContainer>
    );
  }
);

Input.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  isTextaria: PropTypes.bool,
};

export default Input;
