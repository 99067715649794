import PropTypes from "prop-types";
//styles
import {
  Description,
  Ingredients,
  Wrapper,
  Ingredient,
  Price,
} from "./ProductCard.styles";
//components
import ImageCard from "../ImageCard";
import { Link } from "react-router-dom";

function ProductCard({
  id,
  title,
  price,
  ingredients,
  imageSrc,
  rating,
  contain,
  disableFav,
}) {
  return (
    <Wrapper>
      <Link to={`/product/${id}`} style={{ width: "100%" }}>
        <ImageCard
          imageSrc={imageSrc}
          alt={title}
          rating={rating}
          id={id}
          contain={contain}
          disableFav={disableFav}
        >
          {price && <Price>{price}</Price>}
        </ImageCard>

        <Description>
          <p>{title}</p>
          <Ingredients>
            {ingredients &&
              ingredients.map((ingredient) => (
                <Ingredient key={ingredient}>{ingredient}</Ingredient>
              ))}
          </Ingredients>
        </Description>
      </Link>
    </Wrapper>
  );
}

ProductCard.propTypes = {
  imageSrc: PropTypes.string,
  title: PropTypes.string,
  price: PropTypes.string,
  ingredients: PropTypes.array,
  rating: PropTypes.shape({
    stars: PropTypes.number,
    peopleRated: PropTypes.number,
  }),
};

export default ProductCard;
