import tw from "twin.macro";

export const Wrapper = tw.div`
    w-full flex flex-col p-5 gap-7 items-center justify-center 
`;

export const Form = tw.form`
  w-full flex flex-col gap-14 items-center justify-center [&>button]:w-[70%]
`;

export const Spacing = tw.div`
  mb-1
`;
export const FixedNavBtn = tw.div`fixed bottom-10 left-0 w-full text-center`;
export const TitleWrapper = tw.div`
bg-[white] dark:bg-[#343440] py-2 text-center rounded-lg border
border-solid border-2 dark:border-[#292935]
`;
export const Astep = tw.div``; 