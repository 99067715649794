import { useState, useEffect, useMemo } from "react";
import { DayPicker } from "react-day-picker";
import format from "date-fns/format";
import dateToDayMonthYear from "lib/dateToDayMonthYear";
import makeHoursBucketsByDay from "lib/makeHoursBucketsByDay";
import floatHourToDuration from "lib/floatHourToDuration";
import { Spacing } from "components/Layout/Spacing";
import groupByKey from "lib/groupByKey";
import { TimeButton, ButtonContainer } from "./Style";
import Button from "components/Button";
import Modal from "components/Modal";
import "react-day-picker/dist/style.css";

export default function Calendar({
  selectedDate,
  setSelectedDate,
  openingHours,
  bookedSpots,
  duration,
  setSelectedSchedule,
  departmentId,
}) {
  const [hoursBlock, setHoursBlock] = useState([]);
  const [maxClientPerHour, setMaxClientPerHour] = useState(Infinity);
  const [showCalendar, setShowCalendar] = useState(false);
  const bookedSpotsByKey = useMemo(() => {
    if (bookedSpots) {
      const normalizeBookedSpots = bookedSpots.map((b) => {
        return { ...b, bucket: Math.round((b.startTime - 0.1) / 60) * 60 };
      });
      return groupByKey(normalizeBookedSpots, "bucket");
    }
  }, [bookedSpots]);

  useEffect(() => {
    if (selectedDate && openingHours) {
      setHoursBlock(makeHoursBucketsByDay(openingHours, selectedDate.getDay()));
    }
  }, [selectedDate, openingHours]);

  useEffect(() => {
    if (selectedDate && openingHours) {
      setMaxClientPerHour(
        openingHours.filter((day) => selectedDate.getDay() === day.day)[0]
          ?.maxClientPerHour
      );
    }
  }, [selectedDate, openingHours]);

  function handleSelected(date) {
    setSelectedDate(date);
    setShowCalendar(false)
  }

  function bookedSpotsByHour(hour) {
    if (!hour || !bookedSpotsByKey) return;
    return bookedSpotsByKey[
      Object.keys(bookedSpotsByKey)?.filter(
        (key) => key.toString() === (hour * 60)?.toString()
      )[0]
    ];
  }

  return (
    <div>
      <Modal isOpen={showCalendar} closeModal={() => setShowCalendar(false)}>
        <DayPicker
          firstDayOfWeek={1}
          mode="single"
          selected={selectedDate}
          onSelect={handleSelected}
          disabled={{ before: new Date() }}
        />
      </Modal>

  
        <Button
          size="small"
          label={'📅 ' +format(selectedDate, "eeee, dd")}
          onClick={() => setShowCalendar(!showCalendar)}
        />
   

      <Spacing bottom="14" />
      {hoursBlock.map((hour, k) => (
        <ButtonContainer key={hour + k}>
          <TimeButton
            disabled={
              maxClientPerHour <=
              bookedSpotsByHour(Math.round(hour - 0.1))?.length
            }
            onClick={() => {
              setSelectedSchedule({
                department: departmentId,
                startTime: hour * 60,
                endTime: hour * 60 + duration,
                ...dateToDayMonthYear(selectedDate),
              });
            }}
          >
            {floatHourToDuration(hour).text}
          </TimeButton>
        </ButtonContainer>
      ))}

      <div></div>
    </div>
  );
}
