import { motion } from "framer-motion";
import tw, { styled } from "twin.macro";

export const Wrapper = tw.div`
flex flex-row justify-between items-center px-4 py-7
 text-lg text-center dark:text-primary dark:bg-darkGray
`;

export const Avatar = tw.img`
    w-12 h-12 rounded-2xl shadow-button
`;

export const LinksList = tw.ul`
    flex flex-col w-full gap-6
`;

export const ListItem = styled(motion.li)`
  ${tw`border-b  border-black pb-2 text-black text-lg cursor-pointer`}
`;
