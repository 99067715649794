import tw from "twin.macro";

export const Wrapper = tw.div`w-full flex justify-between items-center text-black dark:text-primary`;

export const Total = tw.p`
    text-3xl 
`;

export const Counter = tw.div`
    flex justify-center items-center gap-2
`;

export const Value = tw.span`
    text-base font-semibold
`;
