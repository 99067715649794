import { Player } from "@lottiefiles/react-lottie-player";
import { useTranslation } from "react-i18next";
import lottieSuccess from "./lottieSuccess.json";
import Title from "components/Title";

export default function Confirmation({positive=true, bookingUrl}) {
  const { t } = useTranslation();
  return (
    <>
      
      <Player
        autoplay
        loop
        src={lottieSuccess}
        style={{ height: "50vh", width: "100vh" }}
          />
          <Title fontSize={"md"} level={1}>
        {t("review.thankyou")}
      </Title>
      <div style={{ textAlign: 'center' }}>
      {
        positive && <a style={{color: "white", borderBottom: '1px dashed'}} href={ bookingUrl }>🏆 Share Review</a>
      }
      {
        !positive && <p style={{color:'white', maxWidth:'250px'}}>We are deeply sorry for your experience 🙏🙏. Your feedback is received, we will do our best to improve. Thank you so much! </p>
      }
      </div>
      
    </>
  );
}
