import PropTypes from "prop-types";
import IconButton from "../IconButton";
import { Wrapper } from "./Header.styles";
import Title from "components/Title";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";

function Header({ title }) {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(-1);
  };
  return (
    <Wrapper>
      <IconButton
        onClick={handleClick}
        rounded={false}
        Icon={ChevronLeftIcon}
      />
      <Title fontSize="mdLight">{title}</Title>
     
    </Wrapper>
  );
}

Header.propTypes = {
  title: PropTypes.string,
  avatar: PropTypes.string,
  buttonProps: PropTypes.shape(IconButton.propTypes),
};

export default Header;
