import { motion } from "framer-motion";
import tw from "twin.macro";

export const Warpper = tw(motion.div)`
    flex flex-col gap-7
`;

export const CatgoriesList = tw.div`
  grid grid-cols-2 gap-x-5 gap-y-4
`;
