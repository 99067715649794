import { fetchit } from "lib";

import { API_SCHEDULE } from "config/api";

export function getSchedules({ departmentId, year, month, day }) {
  if (!departmentId || !year || !month || !day) return;
  return fetchit(
    `${API_SCHEDULE}/stats/?department=${departmentId}&year=${year}&month=${month}&day=${day}`,
    null,
    "GET"
  );
}

export function createSchedule(data) {
  return fetchit(API_SCHEDULE, data, "POST");
}
