import { Player } from "@lottiefiles/react-lottie-player";
//import { useTranslation } from "react-i18next";
import lottieSuccess from "./lottieSuccess.json";
import Title from "components/Title";

export default function Confirmation() {
  //const { t } = useTranslation();
  return (
    <>
      <Player
        autoplay
        loop
        src={lottieSuccess}
        style={{ height: "50vh", width: "100vh" }}
          />
          <Title fontSize={"md"} level={1}>

      </Title>
    </>
  );
}
