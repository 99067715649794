import { motion } from "framer-motion";
import tw from "twin.macro";

export const Wrapper = tw(motion.div)`
    w-full flex flex-col p-5 gap-7 items-center justify-center 
`;

export const Form = tw.form`
  w-full flex flex-col gap-14 items-center justify-center [&>button]:w-[70%]
`;

export const Spacing = tw.div`
  mb-1
`;
