import PropTypes from "prop-types";
//components
import RatingTag from "../RatingTag";
//styles
import {
  Wrapper,
  Image,
  FavIcon,
  Rating,
  ChildrenStyles,
} from "./ImageCard.styles";
//icons
import { HeartIcon } from "@heroicons/react/24/solid";
import useFavorites from "hooks/useFavorites";

function ImageCard({
  children,
  alt,
  imageSrc,
  id,
  rating,
  disableFav = true,
  contain,
}) {
  const favorites = useFavorites((state) => state.favorites);
  const toggleFavorites = useFavorites((state) => state.toggleFavorites);

  return (
    <Wrapper>
      <Image src={imageSrc} alt={alt} contain={contain} />
      <FavIcon
        onClick={() => toggleFavorites(id)}
        isFavorite={favorites?.includes(id)}
      >
        {!disableFav && <HeartIcon />}
      </FavIcon>
      <ChildrenStyles>{children}</ChildrenStyles>
      {rating && (
        <Rating>
          <RatingTag {...rating} />
        </Rating>
      )}
    </Wrapper>
  );
}

ImageCard.propTypes = {
  id: PropTypes.string.isRequired,
  alt: PropTypes.string,
  imageSrc: PropTypes.string,
  price: PropTypes.number,
  rating: PropTypes.shape({
    stars: PropTypes.number,
    peopleRated: PropTypes.number,
  }),
};

export default ImageCard;
