import IconButton from "components/IconButton";
import Input from "components/Input";
import { useState } from "react";
import { Image, Title, Wrapper } from "./Onboarding.styles";

import { ArrowRightIcon } from "@heroicons/react/24/solid";
import Layout from "components/Layout";

function Onboarding() {
  const [value, setValue] = useState({
    email: "",
    roomNumber: "",
  });

  const handleChange = (e) => {
    setValue((preValue) => ({
      ...preValue,
      [e.target.name]: e.target.value,
    }));
  };

  const handleClick = () => {
    setValue({
      email: "",
      roomNumber: "",
    });
  };
  return (
    <Layout hideNav="true">
      <Wrapper>
        <Image>
          <img src="https://source.unsplash.com/random/?burger" alt="hero" />
        </Image>
        <Title>Browse your menu and order directly</Title>
        <Input
          type="input"
          name="roomNumber"
          onChange={handleChange}
          value={value.roomNumber}
          placeholder="Enter your room number.."
        />
        <Input
          type="input"
          name="email"
          onChange={handleChange}
          value={value.email}
          placeholder="Enter your email.."
        />
        <IconButton
          Icon={ArrowRightIcon}
          rounded={true}
          onClick={handleClick}
          padding="20"
          bgColor="#FE724C"
        />
      </Wrapper>
    </Layout>
  );
}

export default Onboarding;
