export const API_BASE_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:4040/v1/"
    : "https://api.upoui.com/v1/";

export const MISSING_IMG_PLACE_HOLDER =
  "https://concierge.fra1.cdn.digitaloceanspaces.com/19777200-db15-4fe6-bd50-dbcde2936c3d.jpg";
export const UPLOAD_CDN = "https://concierge.fra1.cdn.digitaloceanspaces.com/";
export const API_AUTH_LOGIN = API_BASE_URL + "auth/login";
export const API_AUTH_LOGOUT = API_BASE_URL + "auth/logout";
export const API_AUTH_REFRESH_TOKENS = API_BASE_URL + "auth/refresh-tokens";
export const API_AUTH_AUTHORIZE = API_BASE_URL + "auth/authorize";
export const API_ROOM = API_BASE_URL + "room";
export const API_UPLOAD = API_BASE_URL + "upload";
export const API_PRODUCT = API_BASE_URL + "product";
export const API_ORDER = API_BASE_URL + "order";
export const API_CATEGORY = API_BASE_URL + "category";
export const API_REVIEW = API_BASE_URL + "review";
export const API_SCHEDULE = API_BASE_URL + "calendar";
export const API_LOCATION = API_BASE_URL + "hotel/public";
export const API_CHECKIN = API_BASE_URL + "reservation";
