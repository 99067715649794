import { useState, useEffect, useRef } from "react";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import Fuse from "fuse.js";
import Input from "components/Input";
import Section from "components/Section";
import ProductCard from "components/ProductCard";
import { Warpper } from "./Search.styles";
import { GET_PRODUCTS } from "config/queries";
import { getProducts } from "services";
import { adaptProducts } from "lib/dataAdapter";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { useThrottle } from "hooks/useThrottle";
import usePlace from "hooks/usePlace";
import Layout from "components/Layout";

let fuse = null;
const options = {
  keys: ["title"],
  minMatchCharLength: 2,
  threshold: 0.5,
};

function Search() {
  const { t } = useTranslation();
  const selected = usePlace((state) => state.selected);
  const [products, setProducts] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState(null);
  const { throttledValue, isPending } = useThrottle(searchTerm, 500);
  const inputRef = useRef(null);

  const { data: productList } = useQuery(GET_PRODUCTS + selected._id, () =>
    getProducts({
      placeId: selected._id,
    })
  );

  useEffect(() => {
    inputRef.current.focus();
    if (Array.isArray(productList?.results)) {
      setProducts(adaptProducts(productList.results));
    }
  }, [productList]);

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    if (products) {
      fuse = new Fuse(products, options);
    }
  }, [products]);

  useEffect(() => {
    if (throttledValue) {
      setResults(fuse.search(throttledValue));
    }
  }, [throttledValue]);

  return (
    <Layout headerVersion="v3" title={selected?.name}>
      <Warpper>
        <Input
          ref={inputRef}
          type="input"
          placeholder={t("placeholder.search")}
          Icon={MagnifyingGlassIcon}
          value={searchTerm}
          onChange={handleChange}
        />

        <Section title={isPending ? "Loading..." : ""}>
          {results?.length > 0 &&
            results.map(({ item }) => <ProductCard {...item} key={item.id} />)}
        </Section>
      </Warpper>
    </Layout>
  );
}

export default Search;
