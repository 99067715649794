import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import fr from "./translations/fr.json";
import en from "./translations/en.json";
import es from './translations/es.json'
import zh from './translations/zh.json'

export const locales = [
  { name: "English", symbol: "en" },
  { name: "Français", symbol: "fr" },
  { name: "中国人", symbol: "zh" },
  { name: "Español", symbol: "es" },
];

const resources = {
  en: {
    translation: {
      ...en,
    },
  },
  fr: {
    translation: {
      ...fr,
    },
  },
  es: {
    translation: {
      ...es,
    },
  },
  zh: {
    translation: {
      ...zh,
    },
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: "en",
    keySeparator: false,

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
