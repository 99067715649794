import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import useCart from "hooks/useCart";
import Layout from "components/Layout";
import NoOrders from "./NoOrders";
import usePlace from "hooks/usePlace";
import { getProducts } from "services";
import { GET_PRODUCTS } from "config/queries";
import { adaptProducts } from "lib/dataAdapter";
import Button from "components/Button";
import OrderCard from "components/OrderCard";

function Order() {
  const { t } = useTranslation();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const mycart = useCart((state) => state.cartContent);
  const selected = usePlace((state) => state.selected);
  const { data: productList } = useQuery(GET_PRODUCTS, () =>
    getProducts({
      placeId: selected._id,
    })
  );

  useEffect(() => {
    if (Array.isArray(mycart) && Array.isArray(productList?.results)) {
      const filterProducts = mycart.map(({ product, cartId }) => {
        return {
          ...productList.results.filter((p) => product === p._id)[0],
          cartId,
        };
      });

      setSelectedProducts(adaptProducts(filterProducts));
    }
  }, [productList, mycart]);

  return (
    <Layout headerVersion="v1">
      {!mycart || (!mycart.length && <NoOrders />)}

      {selectedProducts?.length !== 0 &&
        selectedProducts.map((item, k) => (
          <OrderCard key={item.id + k} {...item} />
        ))}
      {selectedProducts?.length !== 0 && (
        <Link style={{ textAlign: "center" }} to="/checkout">
          <Button
            size="large"
            label={t("proceed.checkout")}
            color="secondary"
            textColor="primary"
          />
        </Link>
      )}
    </Layout>
  );
}

export default Order;
