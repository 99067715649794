import create from "zustand";
import { v4 as uuidv4 } from "uuid";
import { persist } from "zustand/middleware";

const useCart = create(
  persist(
    (set, get) => ({
      cartContent: [],
      addTocart: (id, schedule, depId) => {
        set((state) => ({
          cartContent: [
            ...state.cartContent,
            {
              cartId: uuidv4(),
              product: id,
              department: depId,
              schedule: schedule
            },
          ],
        }));
      },
      clearCart: () => set({ cartContent: [] }),
      removeFromCart: (id) =>
        set((state) => ({
          cartContent: state.cartContent.filter((item) => item.cartId !== id),
        })),
      addNote: (id, note) =>
        set((state) => ({
          cartContent: state.cartContent.map((item) => {
            let itemCopy = { ...item };
            if (itemCopy.cartId.toString() === id.toString()) {
              itemCopy.note = note;
            }
            return itemCopy;
          }),
        })),
    }),
    { name: "cart1" }
  )
);

export default useCart;
