import PropTypes from "prop-types";
import IconButton from "components/IconButton";
import Title from "components/Title";
import { Toggle, Wrapper } from "./HeaderV3.styles";
import useDark from "hooks/useDark";
import { MoonIcon, SunIcon } from "@heroicons/react/24/outline";

function HeaderV3({ title }) {
  const { isDark, toggle } = useDark();

  return (
    <Wrapper>
      <Title fontSize="mdLight">{title}</Title>
      <Toggle onClick={toggle}>{isDark ? <SunIcon /> : <MoonIcon />}</Toggle>
    </Wrapper>
  );
}

HeaderV3.propTypes = {
  title: PropTypes.string,
  buttonProps: PropTypes.shape(IconButton.propTypes),
};

export default HeaderV3;
