import tw, { styled } from "twin.macro";


export const Wrapper = styled.div(({ contain }) => [
  tw`relative w-full bg-white rounded-[1.15rem]`,
  contain && tw`px-8`,
]);



export const Image = styled.img(({ contain }) => [
  tw`object-cover w-full h-[15.5rem]rounded-[1.15rem]`,
  contain && tw`object-contain`,
]);

export const ChildrenStyles = tw.div`
  absolute left-3.5 top-3.5
`;

export const FavIcon = styled.button(({ isFavorite }) => [
  tw`w-8 absolute right-6 top-4 text-primary cursor-pointer`,
  isFavorite && tw`text-red-500`,
]);

export const Rating = tw.div`
 absolute -bottom-4 left-7 flex flex-row w-14 justify-around
`;
