import PropTypes from "prop-types";
//styles
import { Counter, Total, Value, Wrapper } from "./Amount.styles";
//components
import IconButton from "components/IconButton";
//icons
import { MinusIcon, PlusIcon } from "@heroicons/react/24/solid";

function Amount({ price, currency, value, setValue }) {

  const total = price * value;

  const decrease = () => {
    setValue((prev) => (prev > 0 ? prev - 1 : prev));
  };

  const increase = () => {
    setValue((prev) => prev + 1);
  };

  return (
    <Wrapper>
      <Total>
        {total}
        {currency}
      </Total>
      <Counter>
        <IconButton
          Icon={MinusIcon}
          IconColor="#FE724C"
          rounded={true}
          onClick={decrease}
        />
        <Value>{value}</Value>
        <IconButton
          Icon={PlusIcon}
          IconColor="#FE724C"
          rounded={true}
          onClick={increase}
        />
      </Counter>
    </Wrapper>
  );
}

Amount.propTypes = {
  price: PropTypes.number,
};

export default Amount;
