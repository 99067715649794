import React from "react";
import { createGlobalStyle } from "styled-components";
import { GlobalStyles as BaseStyles } from "twin.macro";
import tw from "twin.macro";

const CustomStyles = createGlobalStyle`
body {
  ${tw`w-full md:w-[80%] lg:w-[60%] mx-auto bg-white dark:bg-primarybg  shadow-2xl`}
}
.swiper-slide.tabs {
  flex: auto;
}

.sigCanvas{
  border: 5px solid rgba(173,173,184,1);
  border-radius:10px;
}
`;

const GlobalStyles = () => (
  <>
    <BaseStyles />
    <CustomStyles />
  </>
);

export default GlobalStyles;
