import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Iframe from "react-iframe";
import { getDistance } from "geolib";
import Layout from "components/Layout";
import Card from "components/Card";
import SkeletonLoader from "components/SkeletonLoader";
import usePlace from "hooks/usePlace";
import { nearbyPlaces } from "services";
import { pageIdToWikipedia } from "lib/generateUrls";
import { meterDistanceToTravelMinutes } from "lib/distance";
import { MISSING_IMG_PLACE_HOLDER } from "config/api";
import bannedPageIds from "config/bannedWikis";
import Modal from "components/Modal";

function Nearby() {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [link, setLink] = useState()
  const [places, setPlaces] = useState([]);
  const selected = usePlace((state) => state.selected);

  async function getNearbyPlaces(latitude, longitude) {
    return await nearbyPlaces({ latitude, longitude });
  }

  useEffect(() => {
    if (selected?.loc) {
      getNearbyPlaces(selected.loc[1], selected.loc[0]).then((query) => {
        if (!query?.query?.pages) return;
        let data = [];
        for (let key in query.query.pages) {
          data.push(query.query.pages[key]);
        }
        const filetred = data
          .map((f) => {
            if (f?.coordinates && f?.coordinates[0]?.lat) {
              return {
                ...f,
                distance: getDistance(
                  {
                    latitude: f.coordinates[0].lat,
                    longitude: f.coordinates[0].lon,
                  },
                  { latitude: selected.loc[1], longitude: selected.loc[0] }
                ),
              };
            }
            return f;
          })
          .filter((a) => bannedPageIds.indexOf(a.pageid.toString()) === -1)
          .sort((d, g) => d?.distance - g?.distance);

        setPlaces(filetred);
      });
    }
  }, [selected]);

  return (
    <Layout headerVersion="v3" title={t("nearby") + " " + selected?.name}>
      {Array.isArray(places) &&
        places.map((place, k) => {
          return (
            <Card
              showModal={showModal}
              setShowModal={setShowModal}
              link={pageIdToWikipedia(place?.pageid)}
              setLink={setLink}
              key={k}
              id={k + "e"}
              name={place.title}
              imageSrc={place?.thumbnail?.source || MISSING_IMG_PLACE_HOLDER}
              deliveryTime={meterDistanceToTravelMinutes(place.distance)}
              deliveryPrice={null}
            />
          );
        })}

      <Modal isOpen={showModal} closeModal={() => setShowModal(false)}>
        <Iframe
          url={link}
          width="100%"
          height="100%"
          id=""
          className=""
          display="block"
          position="relative"
        />
      </Modal>

      {!places.length && <SkeletonLoader />}
    </Layout>
  );
}

export default Nearby;
