import { ButtonContainer, ButtonIcon, StyledIcon } from "./Button.styles";
import PropTypes from "prop-types";

function Button({
  disabled,
  color,
  textColor,
  onClick,
  label,
  Icon,
  size = "large",
}) {
  return (
    <ButtonContainer
      color={color}
      textColor={textColor}
      Icon={Icon}
      onClick={onClick}
      disabled={disabled}
      size={size}
    >
      {Icon && (
        <ButtonIcon>
          <StyledIcon>
            <Icon />
          </StyledIcon>
        </ButtonIcon>
      )}
      {label && label}
    </ButtonContainer>
  );
}

Button.defaultProps = {
  label: "Submit",
  color: "secondary",
  textColor: "primary",
  Icon: null,
  disabled: false,
  onClick: () => {},
};

Button.propTypes = {
  label: PropTypes.string,
  color: PropTypes.string,
  textColor: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Button;
