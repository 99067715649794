import tw from "twin.macro";

export const Wrapper = tw.div`
flex flex-row justify-between items-center px-4 py-7
 text-lg text-center bg-lightGray dark:text-primary dark:bg-darkGray shadow-lg
`;

export const Toggle = tw.button`
  w-8 h-8 flex-shrink-0
`;
