import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Description,
  Services,
  Wrapper,
  Service,
  DeliveryInfo,
} from "./Card.styles";
import ImageCard from "components/ImageCard";
import { Delivery, Clock, Verified } from "../Icons";
import RatingTag from "../RatingTag";

function Card({
  id,
  name,
  imageSrc,
  services,
  deliveryTime,
  deliveryPrice,
  ratingTag,
  link,
  setLink,
  setShowModal
}) {


  return (
    <Wrapper
      onClick={() => {
        if (typeof(setShowModal)==="function" ) {
          setShowModal(true)
          setLink(link)
          return
        }
        if (link && !setShowModal) {
          window.open(link, "_blank").focus();
        }
      }}
    >
      <ImageCard imageSrc={imageSrc} alt={name} id={id}>
        {ratingTag && <RatingTag {...ratingTag} />}
      </ImageCard>
      <Link to={link ? "#" : `/product/${id}`}>
        <Description>
          <p>
            {name}{" "}
            <span>
              <Verified width="10" height="8" />
            </span>
          </p>
          <DeliveryInfo>
            {deliveryPrice && (
              <p>
                <span>
                  <Delivery width="12" height="10" />{" "}
                </span>{" "}
                {deliveryPrice}
              </p>
            )}
            {deliveryTime && (
              <p>
                <span>
                  <Clock width="12" height="10" />{" "}
                </span>{" "}
                {deliveryTime}
              </p>
            )}
          </DeliveryInfo>
          <Services>
            {services &&
              services.map((ingredient) => (
                <Service key={ingredient}>{ingredient.toUpperCase()}</Service>
              ))}
          </Services>
        </Description>
      </Link>
    </Wrapper>
  );
}

Card.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  imageSrc: PropTypes.string,
  deliveryPrice: PropTypes.string,
  deliveryTime: PropTypes.string,
  services: PropTypes.array,
};

export default Card;
