function Verified({ width, height, color }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="4" cy="4" r="4" fill="#029094" />
      <path
        d="M2.66699 3.37774L3.66699 4.4444L5.33366 2.66663"
        stroke="white"
      />
    </svg>
  );
}

export default Verified;
