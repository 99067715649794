/**
 *
 * @param {*} isoDay: 0 is sunday.
 * @returns Array
 * @example [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19]
 */
 export default function makeHoursBucketsByDay(openning_hours, isoDay, factor=4) {
    let out = [],
      j = 0;
    const getOpenningHours = openning_hours.filter((o) => o.day === isoDay);
    getOpenningHours.forEach((openning_hour) => {
      const { startTime, endTime } = openning_hour;
      const arrayLength = Math.round((endTime - startTime) / (60/factor));
      for (let i = 0; i < arrayLength; i++) {
        out[j] = Math.round((startTime) / 60) + i / factor;
        j++;
      }
    });
  
    return out;
  }
  