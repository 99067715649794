import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ProductList, Wrapper } from "./Category.styles";
import ProductCard from "components/ProductCard";
import { useQuery } from "react-query";
import Layout from "components/Layout";
import { GET_PRODUCTS } from "config/queries";
import usePlace from "hooks/usePlace";
import { getProducts } from "services";
import { adaptProducts } from "lib/dataAdapter";
import filterProductsByCategory from "lib/filterProductByCat";
import NoOrders from "../order/NoOrders";

function Category() {
  const { categoryId } = useParams();
  const selected = usePlace((state) => state.selected);
  const [products, setProducts] = useState(null);

  const { data: productList } = useQuery(GET_PRODUCTS, () =>
    getProducts({
      placeId: selected._id,
    })
  );

  useEffect(() => {
    if (Array.isArray(productList?.results)) {
      const adaptedProducts = adaptProducts(productList.results);
      const selectedProducts = filterProductsByCategory(
        adaptedProducts,
        categoryId
      );
      setProducts(selectedProducts);
    }
  }, [productList, categoryId]);

  return (
    <Layout headerVersion="v1">
      <Wrapper>
        <ProductList>
          {products && products.length ? (
            products.map((item) => <ProductCard key={item.id} {...item} />)
          ) : (
            <NoOrders />
          )}
        </ProductList>
      </Wrapper>
    </Layout>
  );
}

export default Category;
